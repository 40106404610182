// DOCS: https://developer.zendesk.com/api-reference/widget/introduction/
// This is the flow for authenticating and to prevent calling the jwtFn twice in case Zendesk called
// the callback automatically. It seems that Zendesk calls the jwtFn inconsistently.
// - call zE('webWidget','updateSettings',{webWidget:{authenticate:{chat:{jwtFn:(callback)=>{}}}}}
//   as soon as the user is authenticated to register the callback for fetching JWT from backend
// - call zE('webWidget', 'chat:reauthenticate') to force call jwtFn when the user is authenticated
//   and zE('webWidget:on', 'chat:connected') has fired, but jwtFn has not fired.

import React, { memo, useEffect, useState } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import Api from 'services/Api';
import Auth from 'modules/Auth';
import User from 'modules/User';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import LiveChatLauncher from './src/Launcher';

const ZENDESK_KEY = process.env.ZENDESK_WEB_WIDGET_KEY || '';
enum STATUS {
  ONLINE = 'online',
  OFFLINE = 'offline',
  AWAY = 'away'
}

export const openLiveChat = () => {
  ZendeskAPI('webWidget', 'show');
  ZendeskAPI('webWidget', 'open');
};
export const hideLiveChat = () => ZendeskAPI('webWidget', 'hide');
const webWidgetSettings = {
  chat: {
    departments: {
      enabled: []
    }
  }
};

const LiveChat = () => {
  const isAuthenticated = useAppSelector(Auth.selectors.getIsAuthenticated);
  const dispatch = useAppDispatch();
  const [isLoaded, setLoaded] = useState(false);
  const [isChatConnected, setIsChatConnected] = useState(false);
  const [isChatAuthenticated, setIsChatAuthenticated] = useState(false);
  const [chatStatus, setChatStatus] = useState<STATUS | null>(null);

  useEffect(() => {
    if (isLoaded) {
      hideLiveChat(); // Hide the built-in launcher and show our custom one where we need it
      ZendeskAPI('webWidget:on', 'close', () => {
        hideLiveChat();
        dispatch(User.actions.setLiveChatLauncherVisible(true));
      });
      ZendeskAPI('webWidget:on', 'open', () => {
        dispatch(User.actions.setLiveChatUnreadCount(0));
      });
      ZendeskAPI('webWidget:on', 'chat:unreadMessages', (count: number) => {
        dispatch(User.actions.setLiveChatUnreadCount(count));
      });
      ZendeskAPI('webWidget:on', 'chat:connected', () => {
        setIsChatConnected(true);
      });
      ZendeskAPI('webWidget:on', 'chat:status', (status: STATUS) => {
        setChatStatus(status);
      });
      ZendeskAPI('webWidget:on', 'chat:end', () => {
        hideLiveChat();
        dispatch(User.actions.setLiveChatLauncherVisible(false));
      });
    }
  }, [isLoaded, dispatch]);

  useEffect(() => {
    if (isLoaded && isAuthenticated) {
      try {
        ZendeskAPI('webWidget', 'updateSettings', {
          webWidget: {
            chat: {
              departments: {
                enabled: []
              }
            },
            authenticate: {
              chat: {
                jwtFn: async (callback: any) => {
                  try {
                    // @ts-ignore
                    const { data } = await Api.request.user.getLiveChatJWT();
                    setIsChatAuthenticated(true);
                    callback(data);
                  } catch (e) {
                    callback(); // Call callback so the chat is not hanging in loading state
                    console.log('Error while fetching the LiveChat JWT', e);
                  }
                }
              }
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [isLoaded, isAuthenticated]);

  useEffect(() => {
    if (isChatConnected && isAuthenticated && !isChatAuthenticated) {
      ZendeskAPI('webWidget', 'chat:reauthenticate');
    }
  }, [isChatConnected, isAuthenticated, isChatAuthenticated]);

  useEffect(() => {
    if (chatStatus === STATUS.OFFLINE) {
      ZendeskAPI('webWidget', 'chat:end');
    }
  }, [chatStatus]);

  const handleLoaded = () => setLoaded(true);
  return (
    <>
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY.toString()}
        onLoaded={handleLoaded}
        webWidget={webWidgetSettings}
      />
      <LiveChatLauncher openLiveChat={openLiveChat} />
    </>
  );
};
export default memo(LiveChat);
