import React from 'react';
import cc from 'classcat';
import Button from 'components/Button';
import Markdown from 'components/Markdown';
import type { Authority } from 'lib/constants';
import data from './data';

const AUTHORITY = __ENV__.MRQ_GAMING_AUTHORITY;

interface FundProtectionFormProps {
  onAccept: () => void;
  authority: Authority;
  previewMode?: boolean;
}

const FundProtectionForm = ({
  onAccept,
  previewMode,
  authority = AUTHORITY
}: FundProtectionFormProps) => {
  const content = data[authority]?.content;
  const className = cc([
    'collapsible',
    'form',
    'fundProtection',
    {
      preview: previewMode
    }
  ]);
  return (
    <>
      <form className={className}>
        <div className="fundProtection__statement">
          <Markdown content={content} />
        </div>
        <div className="fieldset fieldset--centerflex">
          <Button
            title="Agree payment terms"
            buttonText="I understand&nbsp;&nbsp;&nbsp;▶"
            type="button"
            name="agreePaymentTerms"
            onClick={onAccept}
          />
        </div>
      </form>
      <hr />
    </>
  );
};

export default FundProtectionForm;
