import React from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import Button from 'components/Button';

const ListButton = ({ icon, buttonText, children, ...props }) => (
  <Button {...props} className={cc(['listButton', props.className])}>
    {!children && icon && <img alt={props.title} className="buttonIcon" src={icon} />}
    {!children && buttonText && <span className="buttonText">{buttonText}</span>}
    {children}
    <svg className="rightIcon">
      <use href="/assets/images1/chevron-right.svg#icon" />
    </svg>
  </Button>
);

ListButton.propTypes = {
  icon: PropTypes.string,
  buttonText: PropTypes.string
};

ListButton.defaultProps = {
  icon: null,
  buttonText: null
};

export default ListButton;
