// TODO: Move plugins to plugins.ts, accept parameters when necessary
import Cookies from 'js-cookie';
import type { JsonObject } from 'type-fest';
import type { Types } from '@amplitude/analytics-browser';
import { Identify, createInstance } from '@amplitude/analytics-browser';
import {
  AMPLITUDE_EVENT_PROP_VIEWPORT_HEIGHT,
  AMPLITUDE_EVENT_PROP_VIEWPORT_WIDTH
} from '@lindar-joy/plugin-default-event-tracking-advanced-browser/lib/cjs/constants';
import {
  DEFAULT_CSS_SELECTOR_ALLOWLIST,
  defaultEventTrackingAdvancedPlugin
} from '@lindar-joy/plugin-default-event-tracking-advanced-browser';
import { affiliateGet } from '@lindar-joy/mrq-affiliate';

interface BaseVariant {
  key: string;
  payload: JsonObject;
}

interface RegularVariant extends BaseVariant {
  experiment_key: string;
  expKey?: never;
}

interface RestartedVariant extends BaseVariant {
  experiment_key?: never;
  expKey: string;
}

export type Variant = RegularVariant | RestartedVariant;

const hasLocation = typeof window.location !== 'undefined';

const amplitude = createInstance() as Types.BrowserClient & {
  originalTrack: Types.BrowserClient['track'];
};
type Track = typeof amplitude.track;

export const getExperiment = (id: string) => {
  const experimentDataCookie = Cookies.get('experiment_data');
  const experimentExclusionCookie = Cookies.get('experiment_exclude');
  let isExcluded = false;
  if (experimentDataCookie) {
    try {
      if (experimentExclusionCookie) {
        isExcluded = JSON.parse(experimentExclusionCookie);
      }
    } catch (e) {
      isExcluded = false;
    }
    if (isExcluded) return undefined;

    try {
      const {
        [id]: { key, payload, experiment_key, expKey }
      }: Record<string, Variant> = JSON.parse(experimentDataCookie);
      return {
        key,
        payload,
        experimentKey: expKey ?? experiment_key
      };
    } catch (e) {
      return undefined;
    }
  }
};

/**
 * Adds location information (href, pathname) to every default event.
 * This solution might be unstable (Safari) and slow; prototype operations are slow and can cause overall performance
 * degradation due to browser engines opting out of optimizations.
 * A more robust solution would be to maintain a very light fork of amplitude.
 */
export const customTrackPlugin = (): Types.BeforePlugin<
  Types.BrowserClient,
  Types.BrowserConfig
> => ({
  name: 'custom-track-plugin',
  type: 'before',
  // eslint-disable-next-line @typescript-eslint/require-await -- Amplitude expects Promise
  setup: async (_, client) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method -- it's how proxy works
    amplitude.track = new Proxy(amplitude.track, {
      // Proxies the "amplitude" wrapper object which wraps the BrowserClient's methods with logging
      // and enriches calls to `track()` from our own code.
      apply: (target, thisArg, [eventInput, eventProperties, eventOptions]: Parameters<Track>) => {
        const locationProperties = {
          // Source: https://github.com/amplitude/Amplitude-TypeScript/blob/c87f8bead0ff7330ff39c9c5a8698ddb65f5eb8e/packages/plugin-page-view-tracking-browser/src/page-view-tracking.ts#L26
          '[Amplitude] Page Location': (hasLocation && window.location.href) || '',
          '[Amplitude] Page Path': (hasLocation && window.location.pathname) || ''
        };
        if (typeof eventInput !== 'string') {
          return target.apply(thisArg, [
            {
              ...eventInput,
              event_properties: {
                ...locationProperties,
                ...eventInput.event_properties
              }
            },
            eventProperties,
            eventOptions
          ]);
        } else {
          return target.apply(thisArg, [
            eventInput,
            {
              ...locationProperties,
              ...eventProperties
            },
            eventOptions
          ]);
        }
      }
    });
    // This convoluted solution is due to Safari. The repetition is because:
    // - I don't have a good idea of how to simplify all this proxying and binding
    // - Every abstraction is seemingly a minefield in Safari
    // - This might not work anyway; there may be another edge case encountered
    const clientProto = Reflect.getPrototypeOf(client);
    if (clientProto) {
      const proto = Reflect.getPrototypeOf(clientProto);
      if (proto && 'track' in proto) {
        Reflect.setPrototypeOf(clientProto, {
          ...proto,
          track: new Proxy(proto.track as Track, {
            // Proxies calls to the internal "client" instance, which "amplitude" (above) wraps over
            apply: (
              target,
              thisArg,
              [eventInput, eventProperties, eventOptions]: Parameters<Track>
            ) => {
              const locationProperties = {
                // Source: https://github.com/amplitude/Amplitude-TypeScript/blob/c87f8bead0ff7330ff39c9c5a8698ddb65f5eb8e/packages/plugin-page-view-tracking-browser/src/page-view-tracking.ts#L26
                '[Amplitude] Page Location': (hasLocation && window.location.href) || '',
                '[Amplitude] Page Path': (hasLocation && window.location.pathname) || ''
              };
              if (typeof eventInput !== 'string') {
                return target.apply(thisArg, [
                  {
                    ...eventInput,
                    event_properties: {
                      ...locationProperties,
                      ...eventInput.event_properties
                    }
                  },
                  eventProperties,
                  eventOptions
                ]);
              } else {
                return target.apply(thisArg, [
                  eventInput,
                  {
                    ...locationProperties,
                    ...eventProperties
                  },
                  eventOptions
                ]);
              }
            }
          })
        });
      }
    }
  }
});

const round = (num: number, decimalPlaces = 2) => {
  const p = 10 ** decimalPlaces;
  const n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};

/** Events already enriched with viewport size */
const screenEnriched = ['[Amplitude] Element Clicked', '[Amplitude] Element Changed'];
const pageViewedEvent = '[Amplitude] Page Viewed';

/**
 * Adds screen size information to every user
 */
export const enrichScreenSizePlugin = (): Types.EnrichmentPlugin<
  Types.BrowserClient,
  Types.BrowserConfig
> => ({
  name: 'enrich-screen-size-plugin',
  type: 'enrichment',
  // eslint-disable-next-line @typescript-eslint/require-await -- Amplitude expects Promise
  async setup(_, client) {
    if (typeof window.screen !== 'undefined') {
      const identifyEvent = new Identify();

      identifyEvent.set('Screen Height', window.screen.height);
      identifyEvent.set('Screen Width', window.screen.width);
      identifyEvent.set('Screen Pixel Ratio', round(window.devicePixelRatio));

      client.identify(identifyEvent);
    }
  },
  // eslint-disable-next-line @typescript-eslint/require-await -- Amplitude expects Promise
  execute: async (event) => {
    // "[Amplitude] Element Clicked/Changed" events already have these properties
    if (!screenEnriched.includes(event.event_type)) {
      // eslint-disable-next-line no-param-reassign -- Amplitude docs recommended
      event.event_properties = {
        [AMPLITUDE_EVENT_PROP_VIEWPORT_HEIGHT]: window.innerHeight,
        [AMPLITUDE_EVENT_PROP_VIEWPORT_WIDTH]: window.innerWidth,
        ...event.event_properties
      };
    }
    return event;
  }
});

/**
 * Extends attribution tracked by Page Viewed
 */
export const enrichPageViewAttribution = (): Types.EnrichmentPlugin<
  Types.BrowserClient,
  Types.BrowserConfig
> => ({
  name: 'extend-page-view-attribution',
  type: 'enrichment',
  // eslint-disable-next-line @typescript-eslint/require-await -- Amplitude expects Promise
  execute: async (event) => {
    const { event_type, event_properties, user_properties } = event;
    if (event_type !== pageViewedEvent || !event_properties) return event;

    const pageLocationUrl = event_properties['[Amplitude] Page Location'];
    if (!pageLocationUrl) return event;

    const searchParams = new URL(pageLocationUrl).searchParams;
    const promoCodeParam = searchParams.get('promoCode')?.toLowerCase();
    const affiliate = affiliateGet(searchParams);

    const updatedUserProperties = {
      ...{
        $set: {
          ...(affiliate?.affid && { 'Affiliate ID': affiliate.affid }),
          ...(promoCodeParam && { 'Promo Code': promoCodeParam }),
          ...user_properties?.$set
        }
      },
      ...{
        $setOnce: {
          ...(affiliate?.affid && { 'Initial Affiliate ID': affiliate.affid }),
          ...(promoCodeParam && { 'Initial Promo Code': promoCodeParam }),
          ...user_properties?.$setOnce
        }
      },
      ...user_properties
    };

    return {
      ...event,
      event_properties: {
        ...(affiliate?.affid && { 'Affiliate ID': affiliate.affid }),
        ...(promoCodeParam && { 'Promo Code': promoCodeParam }),
        ...event_properties
      },
      user_properties: updatedUserProperties
    };
  }
});

amplitude.add(customTrackPlugin());
amplitude.add(enrichPageViewAttribution());
amplitude.add(enrichScreenSizePlugin());
amplitude.add(
  defaultEventTrackingAdvancedPlugin({
    cssSelectorAllowlist: [
      'a:not(.amp-block-track)',
      'button:not(.amp-block-track)',
      'input:not(.amp-block-track)',
      ...DEFAULT_CSS_SELECTOR_ALLOWLIST.slice(3)
    ]
  })
);

export default amplitude;
