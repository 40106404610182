import { getExperiment } from 'lib/analytics';

const variants = {
  CONTROL: 'in-add-payment',
  TREATMENT: 'in-deposit'
};

const flag = 'funds-protection-flow';
const experimentData = getExperiment(flag);
const variant = experimentData?.key;
const experiment = experimentData?.experimentKey;
export default { flag, experimentData, variants, variant, experiment };
