import React from 'react';
import { MagicMove } from 'components/Transitions';
import { DepositLimitBase } from 'components/DepositLimit';
import LimitsToggler from 'components/AddPayment/components/LimitsToggler';

interface SetLimitsProps {
  onDoneDepositLimit: () => void;
}

const SetLimits = ({ onDoneDepositLimit }: SetLimitsProps) => {
  const [showLimits, setShowLimits] = React.useState(false);
  const onShow = () => setShowLimits(true);
  const handleLater = () => onDoneDepositLimit();
  const handleLimitDone = async (request: Promise<any>) => {
    // The request is passed from DepositLimitBase and we are awaiting it here before going to the next step.
    // TODO: Add loading state, as we are waiting for the request to finish before going to the next step.
    await request;
    onDoneDepositLimit();
  };
  return (
    <MagicMove
      className="depositLimit collapsible"
      childKey={showLimits ? 'showLimits' : 'showToggle'}
    >
      {showLimits ? (
        <DepositLimitBase
          // @ts-ignore -- Until we refactor DepositLimitBase to TS
          onDone={handleLimitDone}
          noSubmitInitial={false}
        />
      ) : (
        <LimitsToggler onLater={handleLater} onShow={onShow} />
      )}
    </MagicMove>
  );
};

export default SetLimits;
