import { useState, useEffect, useMemo, useCallback } from 'react';
import type { SecureFieldsData } from '../types';
import useSecureFields from './useSecureFields';
import useFields from './useFields';

type useSecureFieldsManagerProps = {
  field: 'cardNumber' | 'cvv';
  onSuccess: (transactionId: string) => void | Promise<void>;
  onError: (message: string) => void;
};

const useSecureFieldsManager = ({ field, onSuccess, onError }: useSecureFieldsManagerProps) => {
  const { fields, cardNumber, cvv, onFieldChange, onResetFields } = useFields(field);
  const { secureFields, isScriptAvailable, onResetSecureFields } = useSecureFields(fields);
  const [cardType, setCardType] = useState<string | null>(null);

  const isValid = useMemo(
    () =>
      Boolean(field === 'cardNumber' && cardNumber.valid) || Boolean(field === 'cvv' && cvv.valid),
    [field, cardNumber.valid, cvv.valid]
  );

  const onReset = useCallback(() => {
    onResetSecureFields();
    onResetFields();
    setCardType(null);
  }, [onResetFields, onResetSecureFields]);

  useEffect(() => {
    if (secureFields) {
      secureFields.on('ready', () => {
        if (field === 'cardNumber') {
          secureFields.focus('cardNumber');
        }
      });

      secureFields.on('change', (data: SecureFieldsData) => {
        if (data.event.type === 'keyUp') {
          if (data.fields.cardNumber) {
            setCardType(data.fields.cardNumber.paymentMethod || null);
          }

          onFieldChange({
            [data.event.field]: {
              valid:
                data.fields[data.event.field].length === fields[data.event.field]?.length
                  ? data.fields[data.event.field].valid
                  : null,
              empty: data.fields[data.event.field]?.length === 0
            }
          });
        }

        if (data.event.type === 'focus' || data.event.type === 'blur') {
          onFieldChange({
            [data.event.field]: {
              focus: data.event.type === 'focus'
            }
          });
        }
      });

      secureFields.on('success', ({ transactionId }: { transactionId: string }) =>
        onSuccess(transactionId)
      );

      secureFields.on('error', ({ error }: { error: string }) => onError(error));
    }
  }, [field, fields, secureFields, onSuccess, onError, onFieldChange]);

  useEffect(() => {
    if (!isScriptAvailable) {
      onError(`We're experiencing some issues at the moment. Please try again later.`);
    }
  }, [isScriptAvailable, onError]);

  return useMemo(
    () => ({ secureFields, cardNumber, cvv, cardType, isValid, onReset }),
    [secureFields, cardNumber, cvv, cardType, isValid, onReset]
  );
};

export default useSecureFieldsManager;
