import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import { connect } from 'react-redux';
import update from 'update-immutable';
import { nanoid } from 'nanoid';
import reform from 'components/Reform';
import FormError from 'components/FormError';
import Button from 'components/Button';
import { easing } from 'lib/constants';
import Api from 'services/Api';
import { phone as phoneRegex, email as emailRegex, name as nameRegex } from 'lib/valRegexes';
import { screens } from 'components/ReferAFriend/constants';
import RefereeItem from 'components/ReferAFriend/RefereeItem';
import { hasDuplicateValues } from 'lib/valFuncs';

class _FriendsForm extends PureComponent {
  handleFormChange = (e) => {
    this.props.handleChange(e);
    this.updateInvitedFriends();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const res = await this.props.onSubmit(e);
    if (res === true) {
      this.props.goToScreen(screens.completed);
    }
  };

  handleDeleteRow = (event) => {
    this.props.removeField(event.currentTarget.name);
    this.updateInvitedFriends();
  };

  updateInvitedFriends = () => {
    setTimeout(() => {
      const { fieldRefs, updateInvitedFriends } = this.props;
      let validRows = 0;
      for (let i = 0; i < fieldRefs.length; i++) {
        if (
          this.props[`name_${fieldRefs[i]}`].valid &&
          this.props[`contactDetails_${fieldRefs[i]}`].valid
        ) {
          validRows++;
        }
      }
      updateInvitedFriends(validRows);
    }, 0);
  };

  render() {
    const {
      type,
      addField,
      removeField,
      handleFocus,
      loading,
      errorMessage,
      fieldRefs,
      formValid, // TODO: rest
      ...rest
    } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="referAFriend__rowsContainer">
          <FlipMove delay={0} duration={350} easing={easing} staggerDelayBy={80}>
            {fieldRefs.map((fieldRef) => (
              <div key={fieldRef}>
                <RefereeItem
                  fieldId={fieldRef}
                  nameField={rest[`name_${fieldRef}`]}
                  contactField={rest[`contactDetails_${fieldRef}`]}
                  contactFieldType={type}
                  showDeleteButton={fieldRefs.length > 1}
                  handleFormChange={this.handleFormChange}
                  handleFocus={handleFocus}
                  handleDeleteRow={this.handleDeleteRow}
                />
              </div>
            ))}
          </FlipMove>
        </div>
        <div className="referAFriend__addAnother">
          <Button
            icon="/assets/images1/plus-primary.svg"
            className="button--iconButton button__grey"
            onClick={addField}
            name="referFriendAddRow"
            type="button"
          />
          <span onClick={addField}>Add another friend</span>
        </div>
        <div className="referAFriend__centeredContainer">
          <Button
            className="referAFriend__button"
            disabled={!formValid}
            loading={loading}
            title="Send"
            name="referFriendSend"
            buttonText="Send"
            type="submit"
          />
        </div>
        <FormError errorMessage={errorMessage} />
      </form>
    );
  }
}

const Form = reform()(_FriendsForm);

class _RefereeList extends PureComponent {
  constructor(props) {
    super(props);
    this.numRequired = 1;
    this.emailFieldSchema = {
      required: true,
      error: 'Email is required',
      onChange: this.onEmailChange
    };
    this.telFieldSchema = {
      required: true,
      error: 'Phone number is required',
      onChange: this.onTelChange
    };
    this.nameFieldSchema = {
      required: true,
      error: 'Name is required',
      onChange: this.onNameChange
    };
    const initialFieldRef = nanoid();
    this.state = {
      fieldRefs: [initialFieldRef],
      fields: {
        ...[initialFieldRef].reduce((result, ref) => {
          result[`name_${ref}`] = this.nameFieldSchema;
          result[`contactDetails_${ref}`] =
            props.type === 'email' ? this.emailFieldSchema : this.telFieldSchema;
          return result;
        }, {})
      }
    };
  }

  scrollIfLastRow = (rowId, fieldName) => {
    const { fieldRefs } = this.state;
    if (fieldRefs[fieldRefs.length - 1] === rowId) {
      setTimeout(() => this.scrollTo(fieldName));
    }
  };

  onEmailChange = (value, fields, fieldName) => {
    const rowId = fieldName.slice(fieldName.indexOf('_') + 1);
    const otherField = fields[`name_${rowId}`];
    if (otherField.value === '' && value === '') {
      this.setRequired(`name_${rowId}`, fieldName, false);
      return null;
    } else {
      if (!fields[`name_${rowId}`].required) {
        this.setRequired(`name_${rowId}`, fieldName, true, value);
      }
      if (!emailRegex.test(value.trim())) {
        this.scrollIfLastRow(rowId, fieldName);
        return 'Please enter a valid email address';
      }
      if (hasDuplicateValues(fieldName, value, fields)) {
        this.scrollIfLastRow(rowId, fieldName);
        return `You've already entered this email address`;
      }
    }
    return true;
  };

  onTelChange = (value, fields, fieldName) => {
    const rowId = fieldName.slice(fieldName.indexOf('_') + 1);
    const otherField = fields[`name_${rowId}`];
    if (otherField.value === '' && value === '') {
      this.setRequired(`name_${rowId}`, fieldName, false);
      return null;
    } else {
      if (!fields[`name_${rowId}`].required) {
        this.setRequired(`name_${rowId}`, fieldName, true, value);
      }
      if (!phoneRegex.test(value.trim())) {
        this.scrollIfLastRow(rowId, fieldName);
        return 'Please enter a valid phone number';
      }
      if (hasDuplicateValues(fieldName, value, fields)) {
        this.scrollIfLastRow(rowId, fieldName);
        return `You've already entered this phone number`;
      }
    }
    return true;
  };

  onNameChange = (value, fields, fieldName) => {
    const rowId = fieldName.slice(fieldName.indexOf('_') + 1);
    const otherField = fields[`contactDetails_${rowId}`];
    if (otherField.value === '' && value === '') {
      this.setRequired(`contactDetails_${rowId}`, fieldName, false);
      return null;
    } else {
      if (!fields[`contactDetails_${rowId}`].required) {
        this.setRequired(`contactDetails_${rowId}`, fieldName, true, value);
      }
      if (!nameRegex.test(value.trim())) {
        this.scrollIfLastRow(rowId, fieldName);
        return 'Please enter a valid name';
      }
    }
    return true;
  };

  setRequired = (a, b, required, value) => {
    if (required || this.numRequired > 1) {
      this.numRequired += required ? 1 : -1;
      const aVal = required
        ? {
            required: { $set: required }
          }
        : {
            required: { $set: required },
            initial: { $set: '' }
          };
      setTimeout(
        () =>
          this.setState((state) =>
            update(state, {
              fields: {
                [a]: aVal,
                [b]: {
                  required: { $set: required },
                  initial: { $set: required ? value : '' }
                }
              }
            })
          ),
        0
      );
    }
  };

  addField = () => {
    // Limit total winnable free spins to 999
    const count = this.state.fieldRefs.length;
    if (count * this.props.freeRounds < 1000) {
      const key = nanoid();
      this.setState((prevState) => ({
        fieldRefs: [...prevState.fieldRefs, key],
        fields: {
          ...prevState.fields,
          [`name_${key}`]: { ...this.nameFieldSchema, required: false },
          [`contactDetails_${key}`]:
            this.props.type === 'email'
              ? { ...this.emailFieldSchema, required: false }
              : { ...this.telFieldSchema, required: false }
        }
      }));
      setTimeout(() => this.scrollTo(`name_${key}`));
    }
  };

  removeField = (fieldRef) => {
    this.setState((prevState) => {
      const fieldRefs = prevState.fieldRefs;
      const ret = {
        fieldRefs: fieldRefs.filter((ref) => ref !== fieldRef),
        fields: Object.keys(prevState.fields)
          .filter((key) => key !== `name_${fieldRef}` && key !== `contactDetails_${fieldRef}`)
          .reduce(
            (acc, crt) => ({
              ...acc,
              [crt]: prevState.fields[crt]
            }),
            {}
          )
      };
      return ret;
    });
  };

  scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  render() {
    const { fieldRefs, fields } = this.state;
    const { invitedFriends, freeRounds, refer } = this.props;
    return (
      <div>
        <div className="topImage">
          <h2 className="earnings">{invitedFriends * freeRounds}</h2>
        </div>
        <p className="referAFriend__imageLabel">Your potential amount of spins</p>
        <Form
          fields={fields}
          fieldRefs={fieldRefs}
          addField={this.addField}
          removeField={this.removeField}
          submit={refer}
          onChange={this.handleChange}
          setRequired={this.setRequired}
          validateOnPropChange
          {...this.props}
        />
      </div>
    );
  }
}

_RefereeList.propTypes = {
  type: PropTypes.string.isRequired,
  refer: PropTypes.func.isRequired,
  goToScreen: PropTypes.func.isRequired,
  invitedFriends: PropTypes.number,
  freeRounds: PropTypes.number
};

_RefereeList.defaultProps = {
  invitedFriends: 0,
  freeRounds: 0
};

const mapDispatchToProps = (dispatch, { type, goToScreen }) => ({
  refer: async (data) => {
    let res;
    const referees = Object.keys(data)
      .map((key, idx, keys) =>
        idx % 2 === 0
          ? {
              name: data[key].trim(),
              value: data[keys[idx + 1]].trim()
            }
          : undefined
      )
      .filter(Boolean);
    if (type === 'email') {
      res = await Api.actions.user.referByEmail(null, { referees })(dispatch, true);
    } else {
      res = await Api.actions.user.referBySms(null, { referees })(dispatch, true);
    }

    if (res.success) {
      // Refresh the referrals list
      Api.actions.user.getReferralStatus()(dispatch);
      goToScreen(screens.completed);
    }
    return res;
  }
});

export default connect(null, mapDispatchToProps)(_RefereeList);
