import React, { PureComponent } from 'react';
import { compose } from 'redux';
import SimpleSelect from 'components/SimpleSelect';
import reform from 'components/Reform';
import Button from 'components/Button';
import FormError from 'components/FormError';
import {
  DAILY_AMOUNTS,
  MONTHLY_AMOUNTS,
  WEEKLY_AMOUNTS,
  amountFormatter,
  DEPOSIT_LIMIT_INTERVAL
} from 'lib/depositLimit';

export class _DepositLimitForm extends PureComponent {
  handleSubmit = async (e) => {
    e.preventDefault();
    const { onSubmit, onDone } = this.props;
    const res = await onSubmit(e);
    if (res === true && onDone) {
      onDone();
    }
  };

  render() {
    const {
      depositLimitDaily,
      depositLimitWeekly,
      depositLimitMonthly,
      formValid,
      errorMessage,
      loading,
      handleChange,
      handleFocus
    } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className="form depositLimitForm">
        <div className="fieldset fieldset--fullflex">
          <label htmlFor={DEPOSIT_LIMIT_INTERVAL.DAILY}>Daily Deposit Limit</label>
          <SimpleSelect
            onChange={handleChange}
            onFocus={handleFocus}
            numberFormatter={amountFormatter}
            options={DAILY_AMOUNTS}
            name={DEPOSIT_LIMIT_INTERVAL.DAILY}
            value={depositLimitDaily.value}
          />
        </div>
        <div className="fieldset fieldset--fullflex">
          <label htmlFor={DEPOSIT_LIMIT_INTERVAL.WEEKLY}>Weekly Deposit Limit</label>
          <SimpleSelect
            onChange={handleChange}
            onFocus={handleFocus}
            numberFormatter={amountFormatter}
            options={WEEKLY_AMOUNTS}
            name={DEPOSIT_LIMIT_INTERVAL.WEEKLY}
            value={depositLimitWeekly.value}
          />
        </div>
        <div className="fieldset fieldset--fullflex">
          <label htmlFor={DEPOSIT_LIMIT_INTERVAL.MONTHLY}>Monthly Deposit Limit</label>
          <SimpleSelect
            onChange={handleChange}
            onFocus={handleFocus}
            numberFormatter={amountFormatter}
            options={MONTHLY_AMOUNTS}
            name={DEPOSIT_LIMIT_INTERVAL.MONTHLY}
            value={depositLimitMonthly.value}
          />
        </div>
        <div className="fieldset fieldset--centerflex">
          <Button
            disabled={!formValid}
            loading={loading}
            title="Set deposit limits"
            name="saveDepositLimits"
            buttonText="Set limits"
            type="submit"
          />
        </div>
        <FormError errorMessage={errorMessage} />
      </form>
    );
  }
}

export default compose(reform())(_DepositLimitForm);
