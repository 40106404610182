/* eslint-disable no-param-reassign */
import ky from 'ky';
import { cloneDeep, pick } from 'lodash';
import Cookies from 'js-cookie';
import proxyTarget from 'lib/proxyTarget';
import { RequestTransformer, ActionTransformer } from './Transformers';
import EndpointManager from './EndpointManager';

function errorMessage(res) {
  if (!(res instanceof Error)) return res.message;
  if (navigator.onLine === false) {
    return 'Woops! You are not connected to the internet. Please check your connection and try again.';
  } else if (res instanceof ky.TimeoutError) {
    return 'This is taking too long... Please check your connection and try again';
  } else {
    return 'Cannot connect to MrQ. Please try again in a bit.';
  }
}

const errorProps = ['code', 'msg', 'visible', 'successAndNotNull', 'errors', 'data'];
const xsrfCookieName = 'XSRF-TOKEN';
const xsrfHeaderName = 'X-XSRF-TOKEN';

class RequestManager {
  /**
   * To be instantiated globally and used asynchronously.
   * Class representing an api manager that handles raw requests
   *   and redux actions asynchronously. Uses ky internally.
   *   The default request method is GET
   *
   * @class
   * @class
   * @example
   * <pre><code>
   * (async () => {
   *   // Raw request returns a Promise
   *   api.rawRequest(api.endpoints.bingo.gameInProgress({ roomRefName: 'example' }));
   *   // Returns a generated redux action creator. The action contains the request itself in the
   *   meta (method, args) but NOT data api.actions.bingo.gameInProgress({ roomRefName: 'example'
   *   });
   *   // Make a request, returns a promise
   *   api.request.bingo.gameInProgress({ roomRefName: 'example' });
   * })();
   * </pre></code>
   */
  constructor() {
    // TODO: Re-enable cancellable requests if safe from memory leaks
    this._endpointManager = new EndpointManager(__ENV__.MRQ_URL_API, '/endpoints.json');
    this.ky = ky.create({ retry: 0, timeout: 12000 });
    // No need to set form headers, ky handles it automatically
    // No need to set retry hook
    this.endpoints = this._endpointManager.endpoints;
    /**
     * Dispatch a redux action to be handled with redux-promise-middleware
     *
     * @typedef { import('./types').ApiActions } ApiActions
     * @type {ApiActions}
     */
    this.actions = ActionTransformer.transform(cloneDeep(this.endpoints), this._request.bind(this));
    this.request = RequestTransformer.transform(
      cloneDeep(this.endpoints),
      this._request.bind(this)
    );
  }

  // For some reason it doesn't work as static. Is it a babel bug?
  _constructKyConfig({ method, data, isForm, headers }) {
    const config = {
      method,
      throwHttpErrors: false,
      credentials: 'include',
      mode: 'cors',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        ...headers
      }
    };
    if (data) {
      if (isForm) {
        config.body = data;
      } else {
        config.json = data;
      }
    }
    const xsrfToken = Cookies.get(xsrfCookieName);
    if (xsrfToken) config.headers[xsrfHeaderName] = xsrfToken;

    // This header enables Cloudflare functions/_middleware.ts to proxy to custom environments
    if (proxyTarget) config.headers['X-Proxy-Target'] = proxyTarget;

    return config;
  }

  _handleResponse(res) {
    if (!res) return res;

    if (res.success) return res;

    if (res.success === false) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ message: res.msg, status: res.status, ...pick(res, errorProps) });
    }

    let normalizedResponse = res;

    if (typeof res === 'string') {
      // Is nil
      normalizedResponse = JSON.parse(res);
    }

    if (normalizedResponse.success) {
      return normalizedResponse;
    }

    return Promise.reject(
      Object.assign(normalizedResponse, {
        type: 'NETWORK_ERROR',
        visible: true, // FIXME: @BACKEND fix the login endpoint responses
        severity: normalizedResponse instanceof ky.TimeoutError ? 3 : 4,
        msg: errorMessage(normalizedResponse)
      })
    );
  }

  /**
   * A raw http request, thin wrapper over ky
   *
   * @example
   * <pre><code>
   * (async() => {
   *     api.rawRequest(api.endpoints.bingo.gameInProgress({ roomRefName: 'cree' }));
   *     api.rawRequest(api.endpoints.slots.providerByRef({ ref: 'thunderkick' }));
   *     api.rawRequest(api.endpoints.user.enableStickyChat({ enable: 'true' }));
   * })();
   * </pre></code>
   * @param {String} method
   * @param {String} operation
   * @param {Object} [data]
   * @param {Object} [headers]
   * @returns {Promise.<T>}
   */
  async rawRequest(method, operation, data, headers) {
    const isForm = data instanceof FormData;
    const request = this.ky(
      __ENV__.MRQ_URL_API + operation,
      this._constructKyConfig({ method, data, isForm, headers })
    );

    if (isForm) return request;

    return request.json();
  }

  // Avoid cyclical reference to `init`
  _request(method, operation, data, headers) {
    const isForm = data instanceof FormData;
    const request = this.ky(operation, this._constructKyConfig({ method, data, isForm, headers }));
    request.catch(this._handleResponse);

    if (isForm) return request.text().then(this._handleResponse);

    return request.json().then(this._handleResponse);
  }
}

const api = new RequestManager();
export default api;

// TODO: Create batched requests generated action `this.batch`
// Usage example

// (async () => {
//  let req = api.rawRequest(api.endpoints.bingo.gameInProgress({ roomRefName: 'cree' }));
//  req.then((res) => res.data);
//  api.rawRequest(api.endpoints.slots.providerByRef({ ref: 'thunderkick' }));
//  api.rawRequest(api.endpoints.user.enableStickyChat({user: "test", enable: 'true' }));
//  api.actions.bingo.gameInProgress({ roomRefName: 'cree' });
// })();
