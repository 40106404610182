import React, { type MouseEvent } from 'react';
import { MagicMove } from 'components/Transitions';
import LoadingElement from 'components/Loading/LoadingElement';
import SetLimits from 'components/AddPayment/components/SetLimits';
import PaymentMethodsChooser from '../PaymentMethodsChooser';
import FundProtectionForm from '../FundProtectionForm';
import { abFundsProtectionFlow } from '../../experiments';

interface FirstDepositSetupProps {
  hasLoaded: boolean;
  limitsDone: boolean;
  protectionDone: boolean;
  availablePaymentMethodProviders: string[];
  handleClick: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDoneDepositLimit: () => void;
  onDoneProtection: () => void;
}

const skipFundsProtection =
  abFundsProtectionFlow.variant === abFundsProtectionFlow.variants.TREATMENT;
const FirstDepositSetup = ({
  hasLoaded,
  limitsDone,
  protectionDone,
  onDoneDepositLimit,
  availablePaymentMethodProviders,
  handleClick,
  onDoneProtection
}: FirstDepositSetupProps) => (
  <MagicMove childKey={hasLoaded ? 'LoadingElement' : 'firstDepositSetup'}>
    {!hasLoaded ? (
      <LoadingElement />
    ) : (
      <>
        {!limitsDone && <SetLimits key="setLimits" onDoneDepositLimit={onDoneDepositLimit} />}
        {!skipFundsProtection && !protectionDone && (
          <FundProtectionForm
            key="protection"
            authority={__ENV__.MRQ_GAMING_AUTHORITY}
            onAccept={onDoneProtection}
            previewMode={!limitsDone}
          />
        )}
        <PaymentMethodsChooser
          key="chooser"
          onClickHandler={handleClick}
          previewMode={!limitsDone || !(protectionDone || skipFundsProtection)}
          availableTypes={availablePaymentMethodProviders}
        />
      </>
    )}
  </MagicMove>
);

export default FirstDepositSetup;
