import React from 'react';
import PropTypes from 'prop-types';
import { MagicMove } from 'components/Transitions';
import withLazyStyle from 'components/LazyStyle';
import style from './formError.css?lazy';

// TODO: Use children instead of errorMessage
const FormError = ({ errorMessage }) => (
  <div
    className={`fieldset fieldset--fullflex fieldset--errorMessage ${
      errorMessage ? 'fieldset--errorMessage-show' : ''
    }`}
  >
    <MagicMove className="formError">
      {errorMessage ? (
        <div className="formError__content" key="errorMessage">
          <img
            className="formError__icon"
            src="/assets/images1/notification-3.svg"
            title="An error occurred"
            alt=""
          />
          <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        </div>
      ) : null}
    </MagicMove>
  </div>
);

FormError.propTypes = {
  errorMessage: PropTypes.string
};

FormError.defaultProps = {
  errorMessage: null
};

export default withLazyStyle(style)(FormError);
