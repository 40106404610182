export type LogoKey = keyof typeof logos;
// TODO: Move to Wallet module
export const logos = {
  VISA: '/assets/images1/wallet/visa.svg',
  MC: '/assets/images1/wallet/mastercard.svg',
  'MASTER-CARD': '/assets/images1/wallet/mastercard.svg',
  DINERS: '/assets/images1/wallet/diners.svg',
  DISCOVER: '/assets/images1/wallet/discover.svg',
  JCB: '/assets/images1/wallet/jcb.svg',
  'three-uk': '/assets/images1/wallet/three.svg',
  'eeora-uk': '/assets/images1/wallet/ee.svg',
  'eetmo-uk': '/assets/images1/wallet/ee.svg',
  'voda-uk': '/assets/images1/wallet/vodafone.svg',
  'virgin-uk': '/assets/images1/wallet/virgin.svg',
  'o2-uk': '/assets/images1/wallet/o2.svg',
  unknown: '/assets/images1/lobby/mobile.svg',
  PAYPAL: '/assets/images1/wallet/paypal.svg',
  PAYPAL_WHITE: '/assets/images1/wallet/paypal-white.svg',
  PAYPAL_TEXT_WHITE: '/assets/images1/wallet/paypal-text-white.svg',
  CARD: '/assets/images1/lobby/card.svg',
  MOBILE: '/assets/images1/lobby/mobile.svg',
  BANK_ACCOUNT: '/assets/images1/lobby/bank.svg',
  ECOSPEND: '/assets/images1/lobby/bank.svg',
  CREDIT: '/assets/images1/lobby/credit.svg',
  NEGATIVE_ADJUSTMENT: '/assets/images1/lobby/credit-gray.svg',
  REFUND: '/assets/images1/lobby/withdraw.svg',
  WITHDRAW_DENIED: '/assets/images1/account/withdrawal-decline.svg',
  DEPOSIT_VOID: '/assets/images1/account/deposit-void.svg',
  SLOT_TOURNAMENT_ENTRY_FEE: '/assets/images1/account/tournament-win.svg',
  SLOT_TOURNAMENT_WIN: '/assets/images1/account/tournament-entry-fee.svg',
  DEFAULT_GAME: '/assets/images1/account/game-thumb.png'
};

// TODO: Move to Wallet
export const types = {
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  CARD: 'CARD',
  BRAINTREE: 'BRAINTREE',
  MOBILE: 'MOBILE',
  PCI_PROXY: 'PCI_PROXY',
  PAYPAL: 'PAYPAL'
};

export const providers = {
  FONIX: 'FONIX',
  ECOSPEND: 'ECOSPEND',
  PAYPAL: 'PAYPAL',
  PCI_PROXY: 'PCI_PROXY',
  BRAINTREE: 'BRAINTREE'
};

export const labels = {
  PAYPAL: 'PayPal',
  MC: 'MASTERCARD',
  'three-uk': 'THREE UK',
  'eeora-uk': 'EE (ORANGE) UK',
  'eetmo-uk': 'EE (T-MOBILE) UK',
  'voda-uk': 'VODAFONE UK',
  'o2-uk': 'O2 UK',
  'virgin-uk': 'VIRGIN MEDIA UK',
  BANK_ACCOUNT: 'Bank account'
};

export const providerStatus = {
  HIDDEN: 'HIDDEN',
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE'
};

export const easing = 'cubic-bezier(0.5, 0, 0.1, 1.4)';

export const themes = {
  CLASSIC: 'CLASSIC',
  RESKIN: 'RESKIN'
};

export const supportEmail = 'support@mrq.com';
export const supportEmailLink = `mailto:${supportEmail}`;

export const Authority = {
  UKGC: 'UKGC',
  MGA: 'MGA'
} as const;
export type Authority = (typeof Authority)[keyof typeof Authority];
