import React, { useCallback, useEffect, useState } from 'react';
import Wallet from 'modules/Wallet';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Api from 'services/Api';
import usePrevious from 'hooks/usePrevious';
import Analytics from 'modules/Analytics';
import { abFundsProtectionFlow } from '../../experiments';
import FirstDepositSetup from './FirstDepositSetup';

interface FirstDepositSetupAdapterProps {
  hasLoaded: boolean;
  handleClick: (e: any) => void;
  onEagerAcceptFundsProtection: () => void;
}

const FirstDepositSetupAdapter = ({
  hasLoaded,
  handleClick,
  onEagerAcceptFundsProtection
}: FirstDepositSetupAdapterProps) => {
  const [limitsDone, setLimitsDone] = useState(false);
  const [fundProtectionDone, setFundProtectionDone] = useState(false);
  const availablePaymentMethodProviders = useAppSelector(
    // Never because of the selector is created on top of other selectors. It never touches the state directly.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- Creating selectors out of other selectors causes Typescript issues.
    (s) => Wallet.selectors.getAvailablePaymentMethodProviders(s as never)
  );
  const dispatch = useAppDispatch();
  const exposeExperiment = useCallback(() => {
    const { flag, variant, experiment } = abFundsProtectionFlow;
    dispatch(Analytics.actions.exposeExperiment({ flag, variant, experiment }));
  }, [dispatch]);
  useEffect(() => {
    exposeExperiment();
  }, [exposeExperiment]);
  const hasAgreedPaymentTerms = useAppSelector(Wallet.selectors.hasAgreedPaymentTerms);
  const prevHasAgreed = usePrevious(hasAgreedPaymentTerms);
  const agreePaymentTerms = () => Api.actions.wallet.agreePaymentTerms()(dispatch);
  useEffect(() => {
    if (hasAgreedPaymentTerms !== prevHasAgreed && hasAgreedPaymentTerms) {
      setFundProtectionDone(true);
      setLimitsDone(true);
    }
  }, [setLimitsDone, setFundProtectionDone, hasAgreedPaymentTerms, prevHasAgreed]);
  const handleDoneProtection = () => {
    // Don't wait for the request, this is the intentional UX
    onEagerAcceptFundsProtection();
    void agreePaymentTerms();
    setFundProtectionDone(true);
  };
  const handleDoneDepositLimit = () => setLimitsDone(true);

  return (
    <FirstDepositSetup
      hasLoaded={hasLoaded}
      limitsDone={limitsDone}
      protectionDone={fundProtectionDone}
      availablePaymentMethodProviders={availablePaymentMethodProviders}
      handleClick={handleClick}
      onDoneDepositLimit={handleDoneDepositLimit}
      onDoneProtection={handleDoneProtection}
    />
  );
};

export default FirstDepositSetupAdapter;
