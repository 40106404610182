import React from 'react';
import './chip.css';

interface ChipProps {
  label: string;
}

const Chip = ({ label }: ChipProps) => <span className="chip">{label}</span>;

export default Chip;
